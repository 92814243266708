/* eslint-disable quote-props */
/* eslint quote-props: ["error", "consistent"] */
export type PianoKey =
  | 'B8'
  | 'A#8'
  | 'A8'
  | 'G#8'
  | 'G8'
  | 'F#8'
  | 'F8'
  | 'E8'
  | 'D#8'
  | 'D8'
  | 'C#8'
  | 'C8'
  | 'B7'
  | 'A#7'
  | 'A7'
  | 'G#7'
  | 'G7'
  | 'F#7'
  | 'F7'
  | 'E7'
  | 'D#7'
  | 'D7'
  | 'C#7'
  | 'C7'
  | 'B6'
  | 'A#6'
  | 'A6'
  | 'G#6'
  | 'G6'
  | 'F#6'
  | 'F6'
  | 'E6'
  | 'D#6'
  | 'D6'
  | 'C#6'
  | 'C6'
  | 'B5'
  | 'A#5'
  | 'A5'
  | 'G#5'
  | 'G5'
  | 'F#5'
  | 'F5'
  | 'E5'
  | 'D#5'
  | 'D5'
  | 'C#5'
  | 'C5'
  | 'B4'
  | 'A#4'
  | 'A4'
  | 'G#4'
  | 'G4'
  | 'F#4'
  | 'F4'
  | 'E4'
  | 'D#4'
  | 'D4'
  | 'C#4'
  | 'C4'
  | 'B3'
  | 'A#3'
  | 'A3'
  | 'G#3'
  | 'G3'
  | 'F#3'
  | 'F3'
  | 'E3'
  | 'D#3'
  | 'D3'
  | 'C#3'
  | 'C3'
  | 'B2'
  | 'A#2'
  | 'A2'
  | 'G#2'
  | 'G2'
  | 'F#2'
  | 'F2'
  | 'E2'
  | 'D#2'
  | 'D2'
  | 'C#2'
  | 'C2'
  | 'B1'
  | 'A#1'
  | 'A1'
  | 'G#1'
  | 'G1'
  | 'F#1'
  | 'F1'
  | 'E1'
  | 'D#1'
  | 'D1'
  | 'C#1'
  | 'C1'
  | 'B0'
  | 'A#0'
  | 'A0'
  | 'G#0'
  | 'G0'
  | 'F#0'
  | 'F0'
  | 'E0'
  | 'D#0'
  | 'D0'
  | 'C#0'
  | 'C0';

/** Piano keys and it's frequency in pair */
export const PIANO_KEYS = {
  B8: 7902.133,
  'A#8': 7458.62,
  A8: 7040,
  'G#8': 6644.875,
  G8: 6271.927,
  'F#8': 5919.911,
  F8: 5587.652,
  E8: 5274.041,
  'D#8': 4978.032,
  D8: 4698.636,
  'C#8': 4434.922,
  C8: 4186.009,
  B7: 3951.066,
  'A#7': 3729.31,
  A7: 3520,
  'G#7': 3322.438,
  G7: 3135.963,
  'F#7': 2959.955,
  F7: 2793.826,
  E7: 2637.02,
  'D#7': 2489.016,
  D7: 2349.318,
  'C#7': 2217.461,
  C7: 2093.005,
  B6: 1975.533,
  'A#6': 1864.655,
  A6: 1760,
  'G#6': 1661.219,
  G6: 1567.982,
  'F#6': 1479.978,
  F6: 1396.913,
  E6: 1318.51,
  'D#6': 1244.508,
  D6: 1174.659,
  'C#6': 1108.731,
  C6: 1046.502,
  B5: 987.7666,
  'A#5': 932.3275,
  A5: 880,
  'G#5': 830.6094,
  G5: 783.9909,
  'F#5': 739.9888,
  F5: 698.4565,
  E5: 659.2551,
  'D#5': 622.254,
  D5: 587.3295,
  'C#5': 554.3653,
  C5: 523.2511,
  B4: 493.8833,
  'A#4': 466.1638,
  A4: 440,
  'G#4': 415.3047,
  G4: 391.9954,
  'F#4': 369.9944,
  F4: 349.2282,
  E4: 329.6276,
  'D#4': 311.127,
  D4: 293.6648,
  'C#4': 277.1826,
  C4: 261.6256,
  B3: 246.9417,
  'A#3': 233.0819,
  A3: 220,
  'G#3': 207.6523,
  G3: 195.9977,
  'F#3': 184.9972,
  F3: 174.6141,
  E3: 164.8138,
  'D#3': 155.5635,
  D3: 146.8324,
  'C#3': 138.5913,
  C3: 130.8128,
  B2: 123.4708,
  'A#2': 116.5409,
  A2: 110,
  'G#2': 103.8262,
  G2: 97.99886,
  'F#2': 92.49861,
  F2: 87.30706,
  E2: 82.40689,
  'D#2': 77.78175,
  D2: 73.41619,
  'C#2': 69.29566,
  C2: 65.40639,
  B1: 61.73541,
  'A#1': 58.27047,
  A1: 55,
  'G#1': 51.91309,
  G1: 48.99943,
  'F#1': 46.2493,
  F1: 43.65353,
  E1: 41.20344,
  'D#1': 38.89087,
  D1: 36.7081,
  'C#1': 34.64783,
  C1: 32.7032,
  B0: 30.86771,
  'A#0': 29.13524,
  A0: 27.5,
  'G#0': 25.95654,
  G0: 24.49971,
  'F#0': 23.12465,
  F0: 21.82676,
  E0: 20.60172,
  'D#0': 19.44544,
  D0: 18.35405,
  'C#0': 17.32391,
  C0: 16.3516,
};
